<template>
	<div class="m68 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.push({ name: 'Alram' })">
					<div class="top-iconClose">
						<img
							:src="require('@/assets/img/ic_notifications.svg')"
							alt="알림"
						/>
					</div>
				</a>
				<p>마이데이터</p>
			</div>
		</div>
		<!-- End of Header Section -->
		<div class="mydata-intro">
			<div class="data-img">
				<img
					:src="require('@/assets/img/img_m_detail.png')"
					alt="마이데이터 소개자료"
				/>
				<p>소개자료</p>
			</div>
			<div class="box-shadow"></div>
		</div>
		<!-- 서비스 신청 버튼 -->
		<div class="btn-app">
			<button type="button" class="btn-select" @click="clickListener">
				서비스 신청
			</button>
		</div>

		<Footer :id="2" />

		<CodePassModal v-if="isCodeModalShow" @close="codePassModalCloseListener" />
	</div>
</template>

<script>
import Footer from '@/components/Footer';
import CodePassModal from '@/components/modal/CodePassModal';

export default {
	name: 'My',

	components: {
		CodePassModal,
		Footer,
	},

	data() {
		return {
			isCodeModalShow: false,
		};
	},

	methods: {
		codePassModalCloseListener() {
			this.isCodeModalShow = false;
		},

		clickListener() {
			this.$router.push({ name: 'MyTosForm' });
		},
	},
};
</script>
